// @flow
import React from 'react'
import Loading from '../../Shared/Loading'
import type {IContractListItem } from '../../../Services/ContractsListService'
import ConsumerContractListItem from './ConsumerContractListItem'
import { TabViewSection } from '../ProductTypes/ConsumerProductTypesTabView'
import mapProductTypeForURL from '../../../../utils/mapProductTypeForURL'

type Props = {
    selectedProductType: string;
    selectedProductName: string;
    productName: string;
}
type LoadingProps = {
    productName: string;
}
// TODO Determine types.
type Contracts = {
    contracts: IContractListItem[];
    loadingContractsList: boolean;
}

const ContractsListService = React.lazy(() => import('../../../Services/ContractsListService'));
const LoadingComponent = (props: LoadingProps) => <Loading message={`Ladataan ${mapProductTypeForURL(props.productName.toLowerCase()).toLowerCase()}sopimuksia...`}/>

const ConsumerContractsList: React.FunctionComponent<Props> = (props: Props) => {
    const { selectedProductType } = props
    return (
        <React.Suspense fallback={<LoadingComponent productName={selectedProductType}/>}>
            <ContractsListService selectedProductType={selectedProductType}>
                {({contracts, loadingContractsList}: Contracts) => {
                    if(loadingContractsList){
                        return <LoadingComponent productName={selectedProductType}/>
                    } else {
                        return contracts.map((contractListItem: IContractListItem) => (
                            <TabViewSection key={contractListItem.Sopimustunnus}>
                                <ConsumerContractListItem contractListItem={contractListItem}/>
                            </TabViewSection>
                        ))
                    }
                }}
            </ContractsListService>
        </React.Suspense>
    )
}
export default ConsumerContractsList