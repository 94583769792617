import React from 'react';
import moment from 'moment';
import * as XLSX from 'xlsx'
import { ReactComponent as ChevronUp } from '../../assets/images/chevron-up.svg';
import { ReactComponent as ChevronDown } from '../../assets/images/chevron-down.svg';
import MeteringPoint from './MeteringPoint';
import { saveAs } from 'file-saver';
import {
  DateRangePicker,
  FocusedInputShape,
  isInclusivelyBeforeDay
} from 'react-dates';
// import { START_DATE } from 'react-dates/constants'; From what I can tell this should just be 'startDate' so we are trying with that.
import { Customerships, Customership } from '../../types/companies';

type MeteringPoint = {
  GSRN: string,
  address: string,
  zip: string,
  city: string,
  time: string,
  usage: string
}

export const formatConsumption = (x: string): string =>
  x
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    .split('.')[0];

const CompanyCard = ({
  company,
  collapsed,
  handleCardOpening
}: {
  company: Customerships;
  collapsed: boolean;
  handleCardOpening: () => void;
}): JSX.Element => {
  const [tempStartDate, setTempStartDate] = React.useState<moment.Moment>(
    moment().startOf('year')
  );
  const [tempEndDate, setTempEndDate] = React.useState<moment.Moment>(
    moment().endOf('year')
  );
  const [startDate, setStartDate] = React.useState<moment.Moment>(
    moment().startOf('year')
  );
  const [endDate, setEndDate] = React.useState<moment.Moment>(
    moment().endOf('year')
  );
  const [
    focusedInput,
    setFocusedInput
  ] = React.useState<FocusedInputShape | null>(null);

  const filteredCustomerships = (): Customership[] => {
    const customerships: Customership[] = [];
    company.contracts.map(item => {
      const GSRN = item?.gsrnIdentifier;
      const isDuplicate = customerships.find(
        obj => obj.gsrnIdentifier === GSRN
      );
      !isDuplicate && customerships.push(item);
    });
    return customerships;
  };

  const handleDownload = () => {
    const meteringPoints = filteredCustomerships().reduce(
      (acc: MeteringPoint[], customership) => {
        const GSRN = !customership?.gsrnIdentifier ? customership?.meteringpointIdentifier : customership?.gsrnIdentifier
        const address = `${customership?.address}`;
        const zip = `${customership?.zip}`;
        const city = `${customership?.city}`;
        const time = `${startDate.format('DD.MM.YYYY')} - ${endDate.format(
          'DD.MM.YYYY'
        )}`;
        // TODO Check if this ever exists?
        const usage = `${customership.usage.usageSum}`;
        /*  const production = `${customership.contracts[0].usage?.productionSum}`  */ //-ADP
        acc.push({ GSRN, address, zip, city, time, usage });
        return acc;
      },
      []
    );

    // create excel sheet 
    const wb = XLSX.utils.book_new();
    wb.Props = { Title: `Kulutustiedot_${company?.identifier}_${moment().format('YYYYMMDD')}` }
    wb.SheetNames.push('Kulutustiedot')
    const ws = XLSX.utils.json_to_sheet(meteringPoints);
    ws['!cols'] = [{ wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }, { wch: 20 }];
    ws.A1.v = 'GSRN tai mpID';
    ws.B1.v = 'Osoite';
    ws.C1.v = 'Postinumero';
    ws.D1.v = 'Postitoimipaikka';
    ws.E1.v = 'Aika';
    ws.F1.v = 'Kulutus (kWh)';
    wb.Sheets.Kulutustiedot = ws;
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'buffer' })

    saveAs(
      new Blob([wbout], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      }),
      `Kulutustiedot_${company?.identifier
      }_${moment().format('YYYYMMDD')}.xlsx`
    );
  };

  const handleDatesChange = ({
    startDate,
    endDate
  }: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }): void => {
    if (startDate) {
      setTempStartDate(startDate);
    }
    if (endDate) {
      setTempEndDate(endDate);
    }
  };

  const cancelDatesChange = (): void => {
    setFocusedInput(null);
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };
  const confirmDatesChange = (): void => {
    setFocusedInput(null);
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
  };

  const presets = [
    {
      text: 'Vuosi',
      start: moment()
        .subtract(1, 'year')
        .startOf('year'),
      end: moment()
        .subtract(1, 'year')
        .endOf('year'),
      resolution: 'month'
    },
    {
      text: 'Kvartaali',
      start: moment()
        .subtract(3, 'month')
        .startOf('month'),
      end: moment()
        .subtract(1, 'month')
        .endOf('month'),
      resolution: 'month'
    },
    {
      text: 'Kuukausi',
      start: moment()
        .subtract(1, 'month')
        .startOf('month'),
      end: moment()
        .subtract(1, 'month')
        .endOf('month'),
      resolution: 'day'
    }
  ];

  const renderPresets = (): JSX.Element => (
    <>
      {presets && startDate && endDate && (
        <div className="CalendarInfo__presets">
          <span className="title">Edellinen</span>
          {presets.map(({ text, start, end }) => {
            return (
              <button
                key={text}
                className="btnPreset"
                onClick={(): void => {
                  handleDatesChange({ startDate: start, endDate: end });
                }}
              >
                {text}
              </button>
            );
          })}
        </div>
      )}
      <div className="CalendarInfo__footer">
        <button className="btn" onClick={(): void => cancelDatesChange()}>
          Peruuta
        </button>
        <button
          className="btn btnConfirm"
          onClick={(): void => confirmDatesChange()}
        >
          Vahvista
        </button>
      </div>
    </>
  );

  const PrevMonth = (): JSX.Element => (
    <div className="MonthNav__prev">
      <ChevronUp />
    </div>
  );

  const NextMonth = (): JSX.Element => (
    <div className="MonthNav__next">
      <ChevronUp />
    </div>
  );

  return (
    <div className={'card' + (!collapsed ? ' open' : '')}>
      <div className="person-info">
        <h2>{company?.customerName}</h2>
        <p>
          {company?.contracts?.[0]?.address}
          <br />
          {company?.contracts?.[0]?.zip}{' '}
          {company?.contracts?.[0]?.city}
        </p>
      </div>
      <div className="person-actions company-actions">
        <button className="action more-info" onClick={handleCardOpening}>
          {collapsed ? (
            <>
              Käyttöpaikat
              <ChevronDown />
            </>
          ) : (
            <>
              Sulje käyttöpaikat
              <ChevronUp />
            </>
          )}
        </button>
      </div>
      {!collapsed && (
        <div className="company-meteringpoints">
          <header>
            <DateRangePicker
              showDefaultInputIcon
              minimumNights={0}
              startDate={tempStartDate}
              startDateId="pset_companyCard_start"
              endDate={tempEndDate}
              endDateId="pset_companyCard_end"
              onDatesChange={handleDatesChange}
              focusedInput={focusedInput}
              onFocusChange={(focusedInput): void =>
                // setFocusedInput(focusedInput ?? START_DATE) This is weird so lets try otherway.
                setFocusedInput(focusedInput ?? 'startDate')
              }
              isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
              displayFormat="D.M.YYYY"
              numberOfMonths={2}
              renderCalendarInfo={(): JSX.Element => renderPresets()}
              navPrev={<PrevMonth />}
              navNext={<NextMonth />}
              daySize={window.outerWidth < 576 ? 39 : 44}
            />
            <button className="download" onClick={handleDownload}>
              Lataa käyttöpaikkojen kulutustiedot Exceliin
            </button>
          </header>
          {company.contracts.map((mp: Customership, idx: number) => (
            <MeteringPoint
              key={idx}
              mp={mp}
              startDate={startDate}
              endDate={endDate}
              company={company}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default CompanyCard;
