import { AxiosResponse } from 'axios';

// Helper.
import API from './Api';
// Types.
import { ropoInvoice } from '../types/ropo';

export const getRopoInvoices = async (): Promise<ropoInvoice[]> => {

  const invoices: AxiosResponse<ropoInvoice[]> = await API({
    method: 'get',
    url: `/ropo/invoices`
  });

  return invoices.data;
}

export const getRopoPdf = async (invoiceId: string): Promise<string> => {

  const billDetails: AxiosResponse<string> = await API({
      method: 'get',
      url: `/ropo/pdf/${invoiceId}`
    });

  return billDetails.data;
}
