// @flow
import React from 'react'
import { apiUrl } from '../../config/constants'
import mapProductTypeForURL from '../../utils/mapProductTypeForURL'
import Logout from '../Elements/Logout'

export interface IAddress {
    'Vaihtoehtoinen vastaanottaja': string,
    Osoitetyyppi: string,
    Osoite: string,
    Postilokero: string,
    Postinumero: string,
    Postitoimipaikka: string,
    Maa: string,
}

export interface ICustomership {
    email: string
    phone: string
    name: string
    customerIdentifier: string
    AsiakasKey: string,
    Nimi: string,
    'Sähköpostiosoite': string,
    Puhelinnumero: string,
    addresses: Array<IAddress>,
    Asiakasnumero: any // eslint-disable-line
}

type Props = {
    productType: string;
    children: any; // eslint-disable-line
}

type State = {
    customership: ICustomership;
    loading: boolean;
}
export default class CustomershipService extends React.Component<Props, State> {

    constructor(props: Props){
        super(props)
        this.state = {
            customership: null,
            loading: true,
        }
        this.updateCustomership = this.updateCustomership.bind(this)
    }
    loadCustomership = async (): Promise<void> => {
        const { productType } = this.props
        const req = new Request(`${apiUrl}/customership/${mapProductTypeForURL(productType)}`);
        try {
            const response = await fetch(req)
            if(response.status === 401) {
                this.setState({
                    errorMessage: 'Ei oikeuksia tiedon lataamiseen. Kirjaudutaan ulos ...',
                    loading: false,
                    logout: true
                })
            } else if ([404,500,502].includes(response.status)) {
                this.setState({
                    loading: false,
                    errorMessage: 'Tapahtui virhe, yritä myöhemmin uudelleen',
                })
                return null
            } else if(response.ok) {
                const customership = await response.json()
                this.setState({
                    errorMessage: '',
                    loading: false,
                    logout: false,
                    customership
                }
                )
            } else {
                this.setState({
                    loading: false,
                    errorMessage: 'Virhe asiakkuustietoja haettaessa',
                })
            }
        } catch(err) {
            this.setState({
                loading: false,
                errorMessage: 'Yhteysvirhe asiakkuustietoja haettaessa'
            })
        }
    }

    updateCustomership = (): void => {
        this.setState({
            loading: true
        })
        this.loadCustomership()
    }
    componentDidUpdate(prevProps: any): void { // eslint-disable-line
        const { loading } = this.state
        if(prevProps.productType !== this.props.productType && !loading){
            this.loadCustomership()
        }
    }

    componentDidMount(): void {
        this.setState({
            loading: true
        })
        this.loadCustomership()
    }

    render(): JSX.Element {
        const { children } = this.props
        const { loading, customership, errorMessage, logout } = this.state
        if(logout) return <Logout/>
        return children({loading, customership, errorMessage, updateCustomership: this.updateCustomership})
    }
}
