// @flow
import React, { Component } from 'react'
import { scaleLinear } from 'd3-scale'
import { interpolateRgb } from 'd3-interpolate'
import moment from 'moment'
import { Scales, Margins, SvgDimensions, Data, Tick } from './index'
import { DataPoint, UsageData } from '../../store'

// The theme is compiled on build and as such it will always throw an error elsewhere.
// So we disable the error message for the following line.

import { themeDetails } from '../../theme/theme'

// import themeColors from '../../styles/base/_theme.scss'
import themeColors from '../../styles/base/_themevariables.module.scss'

import 'moment/locale/fi'
import ViewChart from '../../utils/ViewChart'
moment.locale('fi')

// TODO determine setstate type.
type Props = {
  data: Data
  margins: Margins
  maxValue: number
  scales: Scales
  svgDimensions: SvgDimensions
  tick?: Tick
  unit: string
  showComparison: boolean
  usageData?: UsageData
  updateUsageData: (value: React.SetStateAction<any>) => void // eslint-disable-line
  isProductionData: boolean //ADP
  maximumResolution: string;
}

type ToolTip = {
  d: DataPoint
  x?: string | number
  y: number
  unit: string
  tick?: Tick
  comparisonMode: boolean
  className: string
  isProductionData: boolean //ADP
}

type frameType = {
  electricity: boolean
  heat: boolean
  water: boolean
}

const ToolTip = ({
  d,
  unit,
  x,
  y,
  tick,
  comparisonMode,
  className,
  isProductionData //ADP
}: ToolTip): JSX.Element => {
  const format =
    tick && (comparisonMode ? tick.hoverFormatExtended : tick.hoverFormat)
  return (
    <g className="tooltip-wrapper">
      {/* Adjustments made for element heights with electricity production data, subtract bottom margin height - ADP */}
      <text className={className} textAnchor="start" x={x} y={isProductionData ? y - 100 : y - 50}>
        {d.vp && isProductionData ? (d.vp).toFixed(2) : (d.v).toFixed(2)} {unit}
      </text>
      <text className={className} textAnchor="start" x={x} y={isProductionData ? y - 120 : y - 70}>
        {format && moment(d.ts).format(format)}
      </text>

      {d.r && (
        <text className={className} textAnchor="start" x={x} y={isProductionData ? y - 80 : y - 30}>
          (mittarilukema {d.r})
        </text>
      )}
    </g>
  )
}
class Bars extends Component<Props> {
  drill = (ts: string): void => {
    const { usageData, updateUsageData } = this.props
    const newStartDate = moment(ts)


    let newEndDate
    if (
      usageData &&
      usageData.frame === 'year' &&
      usageData.level === 'month'
    ) {
      newEndDate = moment(newStartDate).endOf('month')
      updateUsageData({
        ...usageData,
        start: newStartDate.format('YYYY-MM-DD'),
        end: newEndDate.format('YYYY-MM-DD'),
        frame: 'month',
        level: 'day',
      })
    } else if (
      usageData &&
      usageData.frame === 'month' &&
      usageData.level === 'day'
    ) {
      newEndDate = moment(newStartDate).endOf('day')
      updateUsageData({
        ...usageData,
        start: newStartDate.format('YYYY-MM-DD'),
        end: newEndDate.format('YYYY-MM-DD'),
        frame: 'day',
        level: 'hour',
      })
    } else if (
      usageData &&
      usageData.frame === 'week' &&
      usageData.level === 'day'
    ) {
      newEndDate = moment(newStartDate).endOf('day')
      updateUsageData({
        ...usageData,
        start: newStartDate.format('YYYY-MM-DD'),
        end: newEndDate.format('YYYY-MM-DD'),
        frame: 'day',
        level: 'hour',
      })
    } else if (
      usageData &&
      usageData.frame === 'custom' &&
      usageData.level === 'day'
    ) {
      newEndDate = moment(newStartDate).endOf('day')

      updateUsageData({
        ...usageData,
        start: newStartDate.format('YYYY-MM-DD'),
        end: newEndDate.format('YYYY-MM-DD'),
        frame: 'day',
        level: 'hour',
      })
    }
    /* quarter-hour level display only -ADP */
    else if (
      usageData &&
      usageData.frame === 'day' &&
      usageData.level === 'hour'
    ) {
      newEndDate = moment(ts).endOf('hour')
      updateUsageData({
        ...usageData,
        start: newStartDate.format('YYYY-MM-DD HH:mm:ss'),
        end: newEndDate.format('YYYY-MM-DD HH:mm:ss'),
        frame: 'hour',
        level: 'quarter-hour',
      })
    }
  }
  isDrillable = (usageData?: UsageData, maximumResolution?: string): boolean | frameType => {
    if (!usageData) return false
    const { frame } = usageData
    if (frame === "hour") { return false }
    else if (frame === "day" && maximumResolution === "PT15M") {
      return ViewChart.day //allows quarterly data display if true -ADP
    }
    else if (frame === "day") return false
    else if (frame === "year") return ViewChart.month
    else return ViewChart.day;

    /*     switch (frame) {
          case 'hour':  //ADP
            return false
          case 'year':
            return ViewChart.month
          default:
            return ViewChart.day
        } */
  }

  render(): JSX.Element {
    const {
      scales,
      margins,
      data,
      svgDimensions,
      unit,
      tick,
      maxValue,
      showComparison,
      usageData,
      isProductionData,
      maximumResolution
    } = this.props
    const { xScale, yScale } = scales
    const { height } = svgDimensions
    // Get the service type for bar colors. If the type is not recognized
    // default to electricity colors
    let type = ''
    if (
      usageData &&
      usageData.type &&
      themeDetails.services.indexOf(usageData.type) > -1
    ) {
      type = usageData.type
    } else {
      type = 'electricity'
    }

    const colorScale = scaleLinear()
      .domain([0, maxValue])
      .range([
        themeColors[`${type}-bar-primary-light`],
        themeColors[`${type}-bar-primary-dark`],
      ])
      .interpolate(interpolateRgb) // Not sure whats up with this but it seems to work.

    const colorScale2 = scaleLinear()
      .domain([0, maxValue])
      .range([
        themeColors[`${type}-bar-secondary-light`],
        themeColors[`${type}-bar-secondary-dark`],
      ])
      .interpolate(interpolateRgb);

    //add colors for electricity production bars -ADP
    const colorScale3 = scaleLinear()
      .domain([0, maxValue])
      .range([
        themeColors[`${type}-bar-tertiary-light`],
        themeColors[`${type}-bar-tertiary-dark`],
      ])
      .interpolate(interpolateRgb);

    const colorScale4 = scaleLinear()
      .domain([0, maxValue])
      .range([
        themeColors[`${type}-bar-quaternary-light`],
        themeColors[`${type}-bar-quaternary-dark`],
      ])
      .interpolate(interpolateRgb);

    //define second yScale for downward production bars -ADP
    const yScale2 = scaleLinear()
      .domain([maxValue, 0])
      .range([svgDimensions.height - margins.top, 0]); //test 0 instead of margins.bottom -ADP

    const lineHeight = 40
    /*  Define bar attributes depending on whether mapped data is consumption or production data  -ADP */
    //production data
    const vpY = yScale2(0);
    const vpHeight = (d: DataPoint) => yScale2(Math.round((d.vp = d.vp || 0) * 1000) / 1000) /* - margins.bottom */
    const vpFill = (d: DataPoint) => `${colorScale3((d.vp = d.vp || 0))}`
    const vpBarlineY = (d: DataPoint) => yScale2(Math.round((d.vp = d.vp || 0) * 1000) / 1000)  /* - margins.bottom */
    const vpTooltipY = (d: DataPoint) => yScale2(Math.round((d.vp = d.vp || 0) * 1000) / 1000) + margins.top + margins.bottom + 10 /* 10px magic for extra padding -ADP*/
    const vpComparisonFill = (d: DataPoint) => `${colorScale4((d.vp = d.vp || 0))}`
    //consumption data
    const vY = (d: DataPoint) => yScale(Math.round((d.v = d.v || 0) * 1000) / 1000)
    const vFill = (d: DataPoint) => `${colorScale((d.v = d.v || 0))}`
    const vHeight = (d: DataPoint) => height - margins.bottom - yScale((d.v = d.v || 0))
    const vBarlineY = (d: DataPoint) => yScale(Math.round((d.v = d.v || 0) * 1000) / 1000) -
      lineHeight
    const vTooltipY = (d: DataPoint) => yScale((d.v = d.v || 0))
    const vComparisonFill = (d: DataPoint) => `${colorScale2((d.v = d.v || 0))}`

    let bars
    if (data.length > 0) {
      const drillable = this.isDrillable(usageData, maximumResolution)
      const widthMod = showComparison ? 0.5 : 1.0
      // TODO figure out whats up with this. xScale is a function but this seems to work?
      // Possibly? D3 bandScale() returns an object instance of a D3 band class that can access D3 band methods?  -ADP
      const barWidth = xScale.bandwidth() * widthMod
      const className = `bar${drillable ? ' drillable' : ''}`
      bars = data
        .slice(0)
        .reverse()
        .map((d: DataPoint, i) => {
          const comparison = d?.comparison // workaround to make flow happy
          const ts = d.ts
          return (
            <svg className="bar-wrapper" key={i}>
              {/* rect attributes modified to receive variable properties -ADP */}
              <>
                <rect
                  className={className}
                  x={`${xScale(ts)}`}
                  y={isProductionData === true ? vpY : vY(d)}
                  height={isProductionData ? vpHeight(d) : vHeight(d)}
                  onClick={() => drillable && this.drill(ts)}
                  width={`${barWidth}`}
                  fill={isProductionData ? vpFill(d) : vFill(d)}
                />
                <rect
                  className="bar-line"
                  x={`${xScale(ts) + barWidth / 2}`}
                  y={isProductionData ? vpBarlineY(d) : vBarlineY(d)}
                  height={lineHeight}
                  width="1"
                />
                <ToolTip
                  className="tooltip"
                  d={d}
                  x={xScale(ts)}
                  y={isProductionData ? vpTooltipY(d) : vTooltipY(d)}
                  unit={unit}
                  tick={tick}
                  comparisonMode={showComparison}
                  isProductionData={isProductionData ? true : false}
                />
              </>
              {showComparison && comparison && (
                <>
                  <rect
                    className={`${className} comparison-bar`}
                    x={xScale(ts) + barWidth}
                    y={isProductionData ? vpY : vY(comparison)}
                    height={isProductionData ? vpHeight(comparison) : vHeight(comparison)
                    }
                    width={barWidth}
                    fill={isProductionData ? vpComparisonFill(comparison) : vComparisonFill(comparison)}
                    onClick={() => drillable && this.drill(ts)}
                  />
                  <rect
                    className="bar-line"
                    x={`${xScale(ts) + barWidth * 1.5}`}
                    y={isProductionData ? vpBarlineY(comparison) : vBarlineY(comparison)
                    }
                    height={lineHeight}
                    width="1"
                  />
                </>
              )}
              {showComparison && comparison && (
                <ToolTip
                  className="tooltip"
                  d={comparison}
                  x={xScale(ts) + barWidth}
                  y={isProductionData ? vpTooltipY(comparison) : vTooltipY(comparison)}
                  unit={unit}
                  tick={tick}
                  comparisonMode={showComparison}
                  isProductionData={isProductionData ? true : false}
                />
              )}
            </svg>
          )
        })
    }

    return <g className="graph-bars">{bars}</g>
  }
}

export default Bars
